@use "../global/placeholders" as *;
@use "../global/variables" as variables;
@use "../global/mixins" as mixins;
@use "../global/icons" as icons;

.sf-input-wr {
  .sf-date {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #B3B3B3;

    &:hover {
      border: 1px solid #16DEAE;
    }

    &.small {
      height: 40px;
    }
  }
}

.sf-input {
  width: 100%;
  height: 44px;
  border: 1px solid #B3B3B3;
  border-radius: 8px;
  padding: 10px 16px;
  -webkit-appearance: none;
  outline: none;

  &.small {
    height: 40px;
  }

  &:hover {
    border: 1px solid #16DEAE;
  }

  &:active {
    border: 1px solid #16DEAE;
  }

  &:focus {
    border: 1px solid #16DEAE;
  }

  &:focus-visible {
    border: 1px solid #16DEAE;
  }

  &.error {
    border: 1px solid #E25B5C;
  }

  &.readonly {
    pointer-events: none;
  }

}

.sf-err-wr {
  .sf-input-err {
    @extend %flexCenterStart;
    font-size: 12px;
    line-height: 16px;
    margin-top: 6px;
    animation: scale 0.2s, opacity 0.5s;

    > i {
      color: #E25B5C;
      font-size: 13px;
      margin-right: 6px;
    }
  }
}

.sf-phone-input {
  display: flex;
  align-items: center;
  width: 48%;
  flex-shrink: 0;
  margin-bottom: 20px;

  > nz-select {
    border-radius: 8px;
    flex-grow: 1;
    min-width: 100px;
    max-width: 245px;

    > nz-select-top-control {
      border-radius: 8px 0 0 8px !important;
    }
  }

  > input {
    height: 40px;
    display: initial;
    min-width: 70%;
    border-radius: 0 8px 8px 0;
  }
}

.sf-textarea {
  border: 1px solid #B3B3B3;
  border-radius: 8px;
  width: 100%;
  padding: 10px 16px;
  -webkit-appearance: none;
  outline: none;
  min-height: 76px;
  max-height: 300px;

  &:hover {
    border: 1px solid #16DEAE;
  }

  &:active {
    border: 1px solid #16DEAE;
  }

  &:focus {
    border: 1px solid #16DEAE;
  }

  &:focus-visible {
    border: 1px solid #16DEAE;
  }

  &.error {
    border: 1px solid #E25B5C;
  }

  &.readonly {
    pointer-events: none;
  }

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }

}

/* SF button Start */

%sfBtn {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  padding: 0 8px;
  @extend %flexCenterCenter;

  &:focus-visible {
    outline: none;
  }

  > i {
    &:first-child {
      margin-right: 4px;
    }

    &:last-child {
      margin-left: 4px;
    }
  }

  &.big {
    height: 48px;
  }

  &.small {
    height: 40px;
    font-size: 14px;
  }

  .xSmall {
    height: 32px;
  }

  &:hover {
    transition: .3s;
    //box-shadow: 0 0 20px rgba(41, 44, 112, 0.25);
  }

  &.loading {
    font-size: 0;
    pointer-events: none;

    &:before {
      display: none !important;
    }

    &::after {
      display: block;
      content: ' ';
      background-image: url('../../../assets/img/icons/arrows-clockwise.svg');
      @include mixins.square(20px);
      @extend %bgImageContain;
      animation: spin 1s linear infinite;
    }

    > i {
      display: none;
    }

    > span {
      display: none;
    }
  }

}

.sf-btn-primary {
  background-color: #16DEAE;
  border: 1px solid #16DEAE;
  color: #303030;

  @extend %sfBtn;

  &.loading {
    font-size: 0;
    pointer-events: none;

    &:before {
      display: none !important;
    }

    &.loading {
      &::after {
        content: ' ';
        background-image: url('../../../assets/img/icons/arrows-clockwise-white.svg');
      }
    }
  }

  &:disabled {
    background-color: #D3D3D3;
    border-color: #D3D3D3;
    pointer-events: none;
    color: #FFFFFF;
  }
}

.sf-btn-delete {
  background-color: variables.$red;
  border: 1px solid variables.$red;
  color: variables.$white;

  @extend %sfBtn;

  &.loading {
    &::after {
      content: ' ';
      background-image: url('../../../assets/img/icons/arrows-clockwise-white.svg');
    }
  }

  &:disabled {
    background-color: #D3D3D3;
    border-color: #D3D3D3;
    pointer-events: none;
    color: #FFFFFF;
  }
}

.sf-btn-secondary {
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  color: #16DEAE;

  @extend %sfBtn;

  &:hover {
    color: #FFFFFF;
    background-color: #00D8A4;
    border: 1px solid #00D8A4;
  }

  &:disabled {
    background-color: #D3D3D3;
    border-color: #D3D3D3;
    pointer-events: none;
    color: #FFFFFF;
  }
}

.sf-btn-tertiary {
  background-color: #FFFFFF;
  border: 1px solid #16DEAE;
  color: #303030;

  @extend %sfBtn;

  &:hover, &.active {
    color: #303030;
    background-color: #00D8A4;
  }

  &:disabled {
    background-color: #D3D3D3;
    border-color: #D3D3D3;
    pointer-events: none;
    color: #FFFFFF;
  }
}

.sf-btn-bg-none {
  color: #00A3FF;
  background: none;
  border: none;
  height: 48px;
  cursor: pointer;
  font-size: 16px;
  transition: .3s;

  &:hover {
    text-decoration: underline;
  }
}

.sf-white-btn {
  @extend %sfBtn;
  background-color: #FFFFFF;
  color: #303030;
  border: none;

  &.border {
    border: 1px solid #303030;
  }
}

.sf-btn-fifth {
  background-color: #4B57DC;
  border: 1px solid #4B57DC;
  color: #FFFFFF;

  @extend %sfBtn;

  &.loading {
    &::after {
      content: ' ';
      background-image: url('../../../assets/img/icons/arrows-clockwise-white.svg');
    }
  }

  &:disabled {
    background-color: #D3D3D3;
    border-color: #D3D3D3;
    pointer-events: none;
    color: #FFFFFF;
  }

}

.google-btn {
  width: 100%;
  height: 42px;
  background: #FFFFFF;
  border-radius: 8px;
  cursor: pointer;
  color: #000000;
  font-size: 16px;
  @extend %flexCenterCenter;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  &:before {
    content: '';
    @include icons.bgIcon('google');
    width: 36px;
    height: 36px;
    margin-right: 8px;
    background-size: initial;
  }

  border: none;
}

.google-btn-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  height: 42px;
  box-sizing: border-box;
  border: 1px solid #D9D9D9;

  > asl-google-signin-button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    &.active {
      opacity: 0;
      z-index: 2;
    }
  }

  &:hover {
    border: 1px solid #AEAEB6;
  }
}

/* SF Checkbox Start */

.sf-checkbox {
  @extend %swCheckbox;
}

%swCheckbox {
  cursor: pointer;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;

    &:checked + label:after {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 8px;
      width: 6px;
      height: 13px;
      border: solid #28192C;
      border-width: 0 1px 1px 0;
      transform: rotate(45deg);
    }

    &:disabled + label:after, &:disabled + label:before {
      opacity: .5;
    }

    &:disabled + label:before {
      border: 1px solid #E5E5EB;
    }

  }

  label {
    position: relative;
    cursor: pointer;
    width: 100%;
    display: inline-block;

    &::before {
      content: '';
      -webkit-appearance: none;
      background-color: variables.$white;
      border: 1px solid #E5E5EB;
      border-radius: variables.$borderRadiusS;
      padding: 10px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
    }

    &:hover {
      &::before {
        border: 1px solid #AEAEB6;
      }
    }

  }

  &.readonly {
    pointer-events: none;
  }

  &.error {
    label {
      &::before {
        border: 1px solid #E25B5C;
        background-color: #FCEFEF;
      }
    }
  }

  &.pink {

    input {
      &:checked + label:after {
        border-color: white;
      }

      &:checked + label:before {
        border-color: #4b57dc;
        background-color: #4b57dc;
      }
    }

    label {
      &::before {

      }
    }
  }

}

/* SW Checkbox End */

.logo-wrapper {
  margin-right: 10px;
  cursor: pointer;

  display: flex;
  align-items: center;

  a {
    color: black;
    display: flex;
    align-items: center;

    span {
      font-size: 19px;
      margin-left: 6px;
    }
  }
}

/* Info Banners Start */

.sf-info-banner {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: 100%;
  border-radius: 8px;
  font-size: 12px;
  color: #303030;

  &:before {
    font-size: 18px;
    font-weight: bold;
  }

  > div {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 2px;
    text-align: left;

    > div {
      font-weight: 600;
      margin-bottom: 2px;
    }

    p {
      margin-bottom: 0;
      line-height: initial;
    }
  }

  &.success {
    background-color: #CCF8E5;

    &:before {
      color: #229E69;
    }

    > div {
      > div {
        color: #229E69;
      }
    }
  }

  &.info {
    background-color: #C8EBFF;

    &:before {
      color: #4FB2DA;
    }

    > div {
      > div {
        color: #4FB2DA;
      }
    }
  }

  &.warning {
    background-color: #F7D8AA;

    &:before {
      color: #EF9E23;
    }

    > div {
      > div {
        color: #EF9E23;
      }
    }
  }

  &.error {
    background-color: #FFE2DE;

    &:before {
      color: #F07767;
    }

    > div {
      > div {
        color: #F07767;
      }
    }
  }

}

/* Info Banners End */

@keyframes scale {
  0% {
    transform: scaley(0.5);
  }
  100% {
    transform: scaley(1);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.sf-input2-wr {
  position: relative;

  > i {
    position: absolute;
    right: 7px;
    top: 6px;

    &.i-prefix {
      left: 7px;
    }
  }

  &.large {
    > i {
      right: 8px;
      top: 12px;

      &.i-prefix {
        left: 8px;
        width: fit-content;
        min-width: 16px;
      }
    }

    .sf-input2 {
      height: 40px;
    }
  }

  &.has-prefix {
    .sf-input2 {
      padding: 0 28px 0 28px;
    }
  }

  .sf-input2 {
    padding: 0 28px 0 5px;
  }

}


.sf-input2 {
  width: 100%;
  padding: 0 6px 0 6px;
  border: solid 1px rgba(175, 179, 197, 0.5);
  border-radius: 4px;
  color: inherit;
  height: 30px;
  font-size: 13px;

  &:focus {
    border: 1px solid #afb3c5;
  }
}

.icon-tag {
  @extend %icon-tag;

  &-small {
    @extend %icon-tag;
    width: 30px;
    height: 30px;
    font-size: 20px;
  }
}

.sf-spin {
  animation: spin 1s linear infinite;
}
