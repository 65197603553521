@use "../global/mixins" as mixins;
@use "../global/bg-images" as bg_images;
@use "../global/variables" as variables;

$sidesPadding: 16px;

.home-page-main-container {

  .img-vector-CV-wrapper {
    //background-color: #F9FAFB;
    @include bg_images.bgImage('complete-vector');
    background-position: top right;
    max-width: 1600px;
    margin: 0 auto;

    .main-section {
      display: flex;
      margin: 0 auto;
      max-width: variables.$extraLarge;
      padding: 140px $sidesPadding 170px $sidesPadding;
      @include bg_images.bgImage('home-page-cvs', '', 'png');
      background-position: center right;
      background-size: 50%;

      .lef-side {
        max-width: 590px;

        > h1 {
          font-size: 44px;
          line-height: 60px;
          margin-bottom: 20px;
          max-width: 530px;

          > span {
            font-weight: 700;
          }
        }

        > p {
          font-size: 16px;
          margin-bottom: 56px;
          max-width: 412px;
        }

        a {
          min-width: 210px;
          width: fit-content;
        }
      }
    }

    .why-use-us-section {
      background: none;
    }

  }

  .S-logo-sections {
    background-color: #fff;
    @include bg_images.bgImage('vector-horizontal');
    background-position: left;

    .find-new-career-section {
      padding: 0 $sidesPadding;

      section {
        padding: 60px 16px 100px 16px;
        max-width: variables.$extraLarge;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;

        .small-desc {
          font-weight: 600;
        }

        h2 {
          margin-bottom: 12px;
          text-align: center;
          max-width: 730px;
        }

        > a {
          width: 180px;
          box-shadow: variables.$shadowStrong;
        }
      }

    }

    .build-your-resume-section {
      padding: 0 $sidesPadding;
      margin-bottom: 80px;

      section {
        max-width: variables.$extraLarge;
        margin: 0 auto;

        .top-part {
          padding: 10px 0 80px 0;

          > p {
            max-width: 690px;
          }
        }

        .body-part {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          position: relative;

          .step-item {
            text-align: center;
            padding: 16px 0 50px 0;
            width: 50%;


            > i {
              @include mixins.square(126px);
            }

            > diV {
              font-weight: 700;
              font-size: 22px;
              margin-bottom: 8px;
            }

            p {
              max-width: 374px;
              margin: 0 auto;
            }

            &:nth-child(1) {
              border-right: 1px solid #E3E3E3;
              border-bottom: 1px solid #E3E3E3;
            }

            &:nth-child(2) {
              border-bottom: 1px solid #E3E3E3;
            }

            &:nth-child(3) {
              border-right: 1px solid #E3E3E3;
            }
          }

          .sf-icon-icon-logo {
            @include mixins.square(158px);
            position: absolute;
          }
        }
      }
    }
  }

  @media only screen and (max-width: variables.$extraLarge) {
    .img-vector-CV-wrapper {

      .main-section {
        background-size: 45%;
        background-position: 96% 80px;
      }
    }
  }

  @media only screen and (max-width: variables.$largeScreen) {
    .img-vector-CV-wrapper {
      background-size: 100%;

      .main-section {
        padding: 80px 16px 20px 16px;
      }
    }

    .S-logo-sections {
      .build-your-resume-section {
        section {
          .body-part {

            .step-item {
              > i {
                @include mixins.square(100px);
              }

              > div {
                font-size: 20px;
              }

              > p {
                font-size: 14px;
              }
            }

            .sf-icon-icon-logo {
              @include mixins.square(126px);
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 880px) {
    .img-vector-CV-wrapper {
      background-size: 130%;

      .main-section {
        background-image: none;
      }
    }

    .S-logo-sections {
      .build-your-resume-section {

        section {
          .top-part {
            padding: 10px 0 0 0;
          }

          .body-part {

            .step-item {
              width: 100%;
              border-bottom: 1px solid #E3E3E3;
              border-right: none;

              &:nth-child(1) {
                border-right: none;
              }

              &:nth-child(3) {
                border-right: none;
              }
            }

            .sf-icon-icon-logo {
              display: none;
            }
          }
        }
      }
    }

    .main-section {
      flex-direction: column;
      padding: 60px 10px 30px 10px;

      .lef-side {
        max-width: initial !important;
        text-align: center;

        > h1 {
          max-width: 710px !important;
          margin: 0 auto 20px auto;
        }

        > h2 {
          margin: 0 auto;
        }

        > p {
          margin-left: auto;
          margin-right: auto;
          max-width: 500px !important;
        }

        > button, a {
          margin: auto;
        }
      }
    }

  }

  @media only screen and (max-width: variables.$smallScreen) {
    .img-vector-CV-wrapper {
      background-size: 160%;
    }

    .main-section {
      flex-direction: column;
      padding: 60px 10px 30px 10px;
    }
  }

}
