@use "../global/placeholders" as *;

.header-container {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  border-bottom: 1px solid #dfe7ef;
  //background-color: rgba(255,255,255,.7);
  //backdrop-filter: blur(10px);

  .sf-info-banner {
    border-radius: 0;

    .reset-email {
      white-space: nowrap;
    }
  }

  header {
    max-width: 1180px;
    height: 80px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
    box-shadow: 0 3px 4px -2px rgb(40 25 45 / 2%);
    padding: 0 16px;

    nz-divider {
      height: 32px;
    }

    .left-side {
      display: flex;
      align-items: center;

      nav {
        margin-left: 18px;

        ul {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
          padding: 0;
          list-style-type: none;

          li {
            cursor: pointer;
            margin-right: 30px;

            a {
              color: black;
              @extend %flexCenter;

              &.active, &:hover {
                color: #00A3FF;
                //color: #4B57DC;
              }
            }

            .sf-dropdown-wrapper {
              width: 150px;
            }

          }
        }
      }

      .tm-title-wrapper {

        > div {
          padding: 0 8px;
          border-radius: 4px;

          @extend %flexCenter;
          cursor: pointer;

          > div {
            @extend %ellipsis;
            max-width: 200px;
          }

          > i {
            margin-left: 4px;
            display: none;
          }

          &:hover {
            background-color: #F0F0F4;

            > i {
              display: inline-flex;
            }
          }
        }

        > input {
          border: none;
          padding: 0 8px;
          width: 200px;
        }
      }

    }

    .right-side {
      display: flex;
      align-items: center;

      .buttons {
        display: flex;
        align-items: center;

        &.in-header {
          .get-started {
            width: 160px;
          }
        }

        .sf-btn-primary {
          width: 180px;
          margin-left: 20px;
        }

        .user-icon-part {
          user-select: none;

          .user-icon-img {
            width: 40px;
            height: 40px;
            background: #4B57DC;
            border-radius: 50px;
            margin-left: 18px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            letter-spacing: 0.2px;
            font-weight: 500;
          }
        }

        .builder-buttons {
          @extend %flexCenter;

          > i {
            font-size: 22px;
            cursor: pointer;
            margin-left: 6px;
          }

          .ph-arrows-clockwise {
            animation: spin 1s linear infinite;
          }

          .ph-check-circle {
            font-size: 24px;
            color: #24A37D;
          }

          button {
            border: 1px solid #AEAEB5;
            border-radius: 8px;
            margin-left: 6px;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: none;
          }
        }
      }

      .menu-icon {
        //font-size: 26px;
        font-size: 32px;
        cursor: pointer;
        padding: 4px 4px;
        margin-left: 14px;
        display: none;
      }
    }

  }

  @media only screen and (max-width: 920px) {
    header {
      .left-side {
        .logo-wrapper {
          a {
            > span {
              display: block;
            }
          }
        }

        nav {
          display: none;
        }
      }

      .right-side {
        .menu-icon {
          display: block;
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    header {
      .right-side {
        .buttons {
          > a {
            display: none;
          }
        }
      }
    }
  }

}


.header-sidebar-main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 140px);

  .sd-top-part {
    .user-info-sidebar {
      margin-bottom: 20px;
      background: linear-gradient(90.47deg, #4B57DC 18.28%, #25B3E0 100%);
      color: white;
      border-radius: 4px;
      padding: 10px 10px;

      display: flex;
      align-items: flex-start;
      gap: 10px;

      .sidebar-img {
        padding-top: 4px;

        img {
          width: 32px;
          border-radius: 50px;
        }
      }

    }

    .buttons {
      > a {
        button {
          margin-bottom: 8px;
          width: 100%;
          border: 1px solid #00A3FF;
          border-radius: 8px;
          height: 40px;
        }
      }
    }

    nav {
      > ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        > li {

          border-radius: 4px;
          transition: .3s;
          margin-bottom: 6px;

          &:hover {
            background-color: #f0f4f7;
          }

          > a {
            color: black;
            height: 40px;
            font-size: 16px;
            display: flex;
            align-items: center;
            padding: 0 10px;

            > i {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }

  .sd-bottom-part {
    font-weight: 600;

    .social-icons {
      margin-bottom: 6px;
    }
  }

}
