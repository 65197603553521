$globalImagePath: '/assets/img/';

@mixin bgImage($class, $path: '', $imageType: 'svg') {
  background-image: url("#{$globalImagePath}#{$path}#{$class}.#{$imageType}");

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

$imagesArray: (
    ('main-section-vector'),
    ('main-section-vector-bottom'),
    ('vector-horizontal'),
    ('complete-vector'),
    ('vector-half'),
    ('home-page-cvs', '', 'png'),
    ('sign-in-cv', '', 'png'),
    ('sign-up-cv', '', 'png'),
    ('why-us-as-vector'),
    ('cover-letter', 'illustrations/'),
    ('cover-letter-content', 'illustrations/'),
    ('cover-letter-include', 'illustrations/'),
    ('cover-letter-length', 'illustrations/'),
    ('cover-letter-mistakes', 'illustrations/'),
    ('cover-letter-need', 'illustrations/'),
    ('CV-length', 'illustrations/'),
    ('CV-update', 'illustrations/'),
    ('CV-vs-Resume', 'illustrations/'),
    ('fit-CV', 'illustrations/'),
    ('gaps-in-history', 'illustrations/'),
    ('resume-versions', 'illustrations/'),
    ('what-is-resume', 'illustrations/'),
);

@each $class, $path, $type in $imagesArray {
  $imageType: $type;

  @if $type == null {
    $imageType: 'svg';
  }

  .bg-img-#{$class} {
    @include bgImage($class, $path, $imageType);
  }
}
